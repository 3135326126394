<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <app-sheet title="Buy Signals - Good buyer lst 3" :vh="58" >
                        <trader-static-explore-tokens-traded :listId="3"></trader-static-explore-tokens-traded>
                    </app-sheet>
                    
                </v-col>
                <v-col cols="12" md="8">
                    <app-sheet title="Intro" :vh="38" >
                        <user-suggestion-navigator></user-suggestion-navigator>
                    </app-sheet>
                    
                </v-col>
                <v-col cols="12" md="4">
                    <app-sheet title="system actions" :vh="38">

                    <stream-loading-actions></stream-loading-actions>
                    </app-sheet>
                    
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import appSheet from './app-sheet.vue'
import TraderStaticExploreTokensTraded from './static_lists/trader-static-explore-tokens-traded.vue'
import StreamLoadingActions from './stream-loading-actions.vue'
import UserSuggestionNavigator from './user-suggestion-navigator.vue'
export default {

    name: 'view-dashboard-home',
    components:
        {
            StreamLoadingActions,
            appSheet,
                UserSuggestionNavigator,
                TraderStaticExploreTokensTraded
    },
    emits:[
    ],
    data(){return {
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>