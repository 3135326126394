<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col sm="12" md="12">
                    <h2>What would you like to do?</h2>
                </v-col>

                
                <v-col cols="12" md="12">
                    <v-card class="mx-auto" max-width="100%" shaped >
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-title class="text-h5 mb-1">  Explore intel </v-list-item-title>
                                <v-list-item-subtitle>Get an idea of how the market is doing and whats going on. </v-list-item-subtitle>
                            </v-list-item-content>
                                                        <v-list-item-avatar shaped  size="80" >
                                <img src="http://134.209.91.206/static/img/whattodoimgs/explore_intel.png" >
                            </v-list-item-avatar>
                        </v-list-item>
                        <v-card-actions>
                            <v-btn  rounded link to="/intel" > Go </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" md="12">
                    <v-card class="mx-auto" max-width="100%" shaped >
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-title class="text-h5 mb-1">  Run Automated Trade Agents </v-list-item-title>
                                <v-list-item-subtitle>Build , manage edit trade automation with agents </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-avatar shaped  size="80" >
                                <img src="http://134.209.91.206/static/img/whattodoimgs/build_agents.png" >
                            </v-list-item-avatar>
                        </v-list-item>
                        <v-card-actions>
                            <v-btn  rounded link to="/strategy" > Go </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" md="12">
                    <v-card class="mx-auto" max-width="100%" shaped >
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-title class="text-h5 mb-1"> Augmented Aping </v-list-item-title>
                                <v-list-item-subtitle>Manually buy straight from the platform, do due dillegence using a checklist and use simulation for safety.</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-avatar shaped  size="80" >
                                <img src="http://134.209.91.206/static/img/whattodoimgs/ape.png" >
                            </v-list-item-avatar>
                        </v-list-item>
                        <v-card-actions>
                            <v-btn  rounded link to="/swap"  > Get started </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col cols="12" md="12">
                    <v-card class="mx-auto" max-width="100%" shaped >
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-title class="text-h5 mb-1">  Find Traders </v-list-item-title>
                                <v-list-item-subtitle>Build lists of traders, get buy sell and swap signals from them , measure them against the market</v-list-item-subtitle>
                            </v-list-item-content>
                                                        <v-list-item-avatar  shaped  size="80" >
                                <img src="http://134.209.91.206/static/img/whattodoimgs/find_traders.png" >
                            </v-list-item-avatar>
                        </v-list-item>
                        <v-card-actions>
                            <v-btn  rounded  > Get started </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" md="12">
                    <v-card class="mx-auto" max-width="100%" shaped >
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-title class="text-h5 mb-1">  Find Tokens </v-list-item-title>
                                <v-list-item-subtitle>Build lists of tokens,Analyze the traders , activity , fingerprints and performance </v-list-item-subtitle>
                            </v-list-item-content>
                                                        <v-list-item-avatar shaped  size="80" >
                                <img src="http://134.209.91.206/static/img/whattodoimgs/find_tokens.png" >
                            </v-list-item-avatar>
                        </v-list-item>
                        <v-card-actions>
                            <v-btn  rounded  > Get started </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" md="12">
                    <v-card class="mx-auto" max-width="100%" shaped >
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-title class="text-h5 mb-1">  Import your bag </v-list-item-title>
                                <v-list-item-subtitle>Import your bag into an existing selloff agent </v-list-item-subtitle>
                            </v-list-item-content>
                                                        <v-list-item-avatar shaped  size="80" >
                                <img src="http://134.209.91.206/static/img/whattodoimgs/import_your_bag.png" >
                            </v-list-item-avatar>
                        </v-list-item>
                        <v-card-actions>
                            <v-btn  rounded link to="/app/selloff/import"  > Get started </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>




                <v-col cols="12" md="12">
                    
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'user-suggestion-navigator',
    components:{
    },
    emits:[
    ],
    data(){return {

        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>